import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import Markdown from '../utils/markdown'
import { isType } from '../utils/typecheck'
import JobDescription from '../components/sections/jobDescription'
import WideArrowLink from '../components/simple/wideArrowLink'
import TextImage from '../components/sections/textImage'
import CTASection from '../components/sections/ctaSection'

export const query = graphql`
    query getCareer {
        allAirtable(
            filter: { table: { eq: "Karriere" } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Type
                    Content
                    Attachments {
                        src
                        srcSet
                        sizes
                    }
                    Slugs
                }
            }
        }
    }
`

export default function JobsPage({ data }) {
    const items = data.allAirtable.nodes

    let introductionElements = []
    let jobDescriptionElements = []
    let locationElements = []
    let ctaElements = []

    let kickerIndex = -1
    items.map((item) => {
        if (isType(item.data.Type, 'Kolumne')) kickerIndex++

        if (kickerIndex === 0) {
            introductionElements.push(item)
        } else if (kickerIndex === 1) {
            jobDescriptionElements.push(item)
        } else if (kickerIndex === 2) {
            locationElements.push(item)
        } else if (kickerIndex === 3) {
            ctaElements.push(item)
        }
    })

    const introductionSection = introductionElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'H1'):
                return <h1 key={index}>{formattedContent}</h1>

            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'Marginalie'):
                return (
                    <aside key={index} className="marginal">
                        {formattedContent}
                    </aside>
                )

            case isType(item.data.Type, 'H2'):
                return <h2 key={index}>{formattedContent}</h2>

            case isType(item.data.Type, 'Teasertext'):
                return (
                    <p key={index} className="teaser h2">
                        {formattedContent}
                    </p>
                )
        }
    })

    const jobDescriptionSection = (
        <JobDescription jobDescriptionElements={jobDescriptionElements} />
    )

    const locationSection = locationElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Bild'):
                return (
                    <figure key={index} className="single-image">
                        <img
                            alt=""
                            srcSet={item.data.Attachments[0].srcSet}
                            sizes={item.data.Attachments[0].sizes}
                        />
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Bild-Text'):
            case isType(item.data.Type, 'Text-Bild'):
                return (
                    <TextImage
                        key={index}
                        type={item.data.Type}
                        image={item.data.Attachments?.[0].url}
                        text={item.data.Content}
                    />
                )

            case isType(item.data.Type, 'Link'):
                return <WideArrowLink key={index} text={item.data.Content} />
        }
    })

    const ctaSection = <CTASection ctaElements={ctaElements} />

    return (
        <ContentContainer>
            <Layout>
                {introductionSection}
                {jobDescriptionSection}
                {locationSection}
                {ctaSection}
            </Layout>
        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    h1 {
        margin-bottom: 1.5rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column-end: span 15;
            margin-bottom: 4rem;
        }
    }

    .teaser {
        padding-top: 0;
    }

    .marginal {
        grid-column-end: -5;
        font-size: 1.222rem;
        font-family: 'SawtonBauhaus';
        line-height: 1.272;

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column-end: span 4;
        }

        br:first-of-type {
            margin-bottom: 0.5rem;
        }

        a {
            font-size: 0.888rem;

            &[href*='mailto'] {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .job {
        grid-column: 3 / -3;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -4;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }
    }
`
